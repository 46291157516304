<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.state" placeholder="结清状态" default-value="">
                <a-select-option value="0">清算待确认</a-select-option>
                <a-select-option value="1">打款中</a-select-option>
                <a-select-option value="2">打款成功</a-select-option>
                <a-select-option value="3">打款失败</a-select-option>
              </a-select>
            </a-form-item>

            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryFunc" :loading="btnLoading">搜索</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="() => this.searchData = {}">重置</a-button>
            </span>
          </div>
        </a-form>
      </div>

      <!-- 列表渲染 -->
      <JeepayTable
        @btnLoadClose="btnLoading=false"
        ref="infoTable"
        :initData="true"
        :reqTableDataFunc="reqTableDataFunc"
        :tableColumns="tableColumns"
        :searchData="searchData"
        rowKey="id"
        :tableRowCrossColor="true"
      >
        <template slot="timeSlot" slot-scope="{record}">
          <div class="order-list">
            <p><span style="color:#729ED5;background:#e7f5f7">清算开始</span>{{ record.startTime }}</p>
            <p><span style="color:#56cf56;background:#d8eadf">清算结束</span>{{ record.endTime }}</p>
            <p><span style="color:#fff;background:#E09C4D">转账成功</span>{{ record.successTime }}</p>
          </div>
        </template>
        <template slot="stateSlot" slot-scope="{record}">
          <a-tag
            :key="record.state"
            :color="record.state === 0?'blue':record.state === 1?'orange':record.state === 2?'green':record.state === 3?'':'volcano'"
          >
            {{ record.state === 0?'清算待确认':record.state === 1?'打款中':record.state === 2?'打款成功':record.state === 3?'打款失败':record.state }}
          </a-tag>
        </template>

        <template slot="opSlot" slot-scope="{record}">  <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button type="link" v-if="$access('ENT_PAY_LIQUIDATE_VIEW')" @click="detailFunc(record.id)">详情</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>

    <!-- 日志详情抽屉 -->
    <template>
      <a-drawer
        width="50%"
        placement="right"
        :closable="true"
        :visible="visible"
        :title="visible === true? '订单详情':''"
        @close="onClose"
      >
        <a-row justify="space-between" type="flex">
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户名称">
                {{ detailData.mchName }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="商户号">
                {{ detailData.mchNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="应用id">
                {{ detailData.appId }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="总笔数">
                {{ detailData.totalNumber }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="总金额">
                <a-tag color="green">
                  {{ detailData.amount }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="总手续费">
                <a-tag color="green">
                  {{ detailData.feeAmount }}
                </a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="清算金额">
                <a-tag color="green">
                  {{ detailData.liquidationAmount }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="清算开始时间">
                {{ detailData.startTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="清算结束时间">
                {{ detailData.endTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="创建时间">
                {{ detailData.ctime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="转账成功时间">
                {{ detailData.successTime }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="结算状态">
                <a-tag :color="detailData.state === 0?'blue':detailData.state === 1?'orange':detailData.state === 2?'green':detailData.state === 3?'':'volcano'">
                  {{ detailData.state === 0?'清算待确认':detailData.state === 1?'打款中':detailData.state === 2?'打款成功':detailData.state === 3?'打款失败':detailData.state }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="银行code">
                {{ detailData.bankCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="银行卡号">
                {{ detailData.bankCard }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="账户类型">
                {{ detailData.accountType }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="银行名称">
                {{ detailData.bankName }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="收款人">
                {{ detailData.payee }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="手机号">
                {{ detailData.phone }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="第三方订单号">
                {{ detailData.channelOrderNo }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="渠道支付错误码">
                {{ detailData.errCode }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="渠道支付错误描述">
                {{ detailData.errMsg }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :sm="12">
            <a-descriptions>
              <a-descriptions-item label="备注">
                {{ detailData.remark }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-drawer>
    </template>

  </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { API_URL_LIQUIDATE_LIST, req, payOrder } from '@/api/manage'
import moment from 'moment'
import { formatAmount } from '@/utils/number.js'

// eslint-disable-next-line no-unused-vars
const tableColumns = [
  { key: 'totalNumber', title: '总笔数', dataIndex: 'totalNumber', width: '100px' },
  { key: 'amount', title: '总金额', dataIndex: 'amount', customRender: text => formatAmount(text) },
  { key: 'feeAmount', dataIndex: 'feeAmount', title: '总手续费', customRender: text => formatAmount(text), width: 140 },
  { key: 'liquidationAmount', title: '清算金额', dataIndex: 'liquidationAmount', customRender: text => formatAmount(text), width: 180 },
  { key: 'mchNo', dataIndex: 'mchNo', title: '商户号' },
  { key: 'bankCard', dataIndex: 'bankCard', title: '银行卡号' },
  { key: 'state', title: '结清状态', scopedSlots: { customRender: 'stateSlot', width: 100 } },
  { title: '时间', scopedSlots: { customRender: 'timeSlot' }, width: 205 },
  { key: 'op', title: '操作', width: '100px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]

export default {
  name: 'IsvListPage',
  components: { JeepayTable, JeepayTableColumns, JeepayTextUp },
  data () {
    return {
      btnLoading: false,
      tableColumns: tableColumns,
      searchData: {},
      visible: false,
      detailData: {},
      payWayList: [],
      payOrderId: ''
    }
  },
  computed: {
  },
  methods: {
    queryFunc () {
      this.btnLoading = true
      this.$refs.infoTable.refTable(true)
    },
    // 请求table接口数据
    reqTableDataFunc: (params) => {
      return req.list(API_URL_LIQUIDATE_LIST, params)
    },
    searchFunc: function () { // 点击【查询】按钮点击事件
      this.$refs.infoTable.refTable(false)
    },
    handleOk () {
      payOrder(this.payOrderId).then(res => {
      this.$message.success(res)
    })
    },
    detailFunc: function (id) {
      const that = this
      req.getById(API_URL_LIQUIDATE_LIST, id).then(res => {
        res.amount = formatAmount(res.amount)
        res.feeAmount = formatAmount(res.feeAmount)
        res.liquidationAmount = formatAmount(res.liquidationAmount)
        that.detailData = res
      })
      this.visible = true
    },
    moment,
    onClose () {
      this.visible = false
    },
    changeStr2ellipsis (orderNo, baseLength) {
      const halfLengh = parseInt(baseLength / 2)
      return orderNo.substring(0, halfLengh - 1) + '...' + orderNo.substring(orderNo.length - halfLengh, orderNo.length)
    }
  }
}
</script>
<style lang="less" scoped>
.order-list {
  -webkit-text-size-adjust:none;
  font-size: 12px;
  display: flex;
  flex-direction: column;

  p {
    white-space:nowrap;
    span {
      display: inline-block;
      font-weight: 800;
      height: 18px;
      line-height: 18px;
      width: 56px;
      border-radius: 5px;
      text-align: center;
      margin-right: 2px;
    }
  }
}
/deep/.ant-descriptions-item:nth-child(1) {
  width: 160px;
}
</style>
